import { HttpClient } from "@angular/common/http";
import { Inject } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/authservice/auth.service";
import { DataService } from "src/app/sharedservice/data.service";

declare interface RouteInfo {
  path: any[];
  title: string;
  param: {};
  icon: string;
  class: string;
  children: RouteInfo[];
}
export const ROUTES: RouteInfo[] = [
  {
    path: ["/dashboard"],
    title: "Dashboard",
    icon: "ni-tv-2 text-primary",
    class: "",
    param: {},
    children: [],
  },

  {
    path: ["/user-profile"],
    title: "Profile",
    icon: "ni-single-02 text-yellow",
    class: "",
    param: {},
    children: [],
  },

  {
    path: ["#"],
    title: "Attendance",
    icon: "fas fa-user-check text-success",
    class: "",
    param: {},
    children: [
      {
        path: ["/attendance-request", "new"],
        title: "Attendance Request",
        icon: "fas fa-sign-out-alt text-pink",
        class: "",
        param: {},
        children: [],
      },
      {
        path: ["/substitute-leave", "new"],
        title: "Substitute Leave Earn",
        icon: "fas fa-sign-out-alt text-info",
        class: "",
        param: {},
        children: [],
      }
    ],
  },
  {
    path: ["/leaves", "new"],
    title: "Leave",
    icon: "fas fa-sign-out-alt text-pink",
    class: "",
    param: {},
    children: [
      {
        path: ["/leaves", "new"],
        title: "Leave Form",
        icon: "fas fa-sign-out-alt text-pink",
        class: "",
        param: {},
        children: []
      },
      {
        path: ["/cancel-leave-list", "Open"],
        title: "Leave Cancelation",
        icon: "fas fa-bus text-yellow",
        class: "",
        param: {},
        children: []
      },
    ]
  },

  // {
  //   path: ["/leaves","new"],
  //   title: "Leave",
  //   icon: "fas fa-sign-out-alt text-pink",
  //   class: "",
  //   param: {},
  //   children: [],
  // },

  {
    path: ["/salary-advance", "new"],
    title: "Salary Advance",
    icon: "fas fa-money-check-alt text-info",
    class: "",
    param: {},
    children: []
    // children: [
    //   {
    //     path: "/salary-advance",
    //     title: "Salary",
    //     icon: "fas fa-money-check-alt text-info",
    //     class: "",
    //     param: { doc_no: "new" },
    //     children: [],
    //   },
    //   {
    //     path: "/loan-advance",
    //     title: "Loan",
    //     icon: "fas fa-money-check-alt text-info",
    //     class: "",
    //     param: { doc_no: "new" },
    //     children: [],
    //   },
    // ],
  },

  /*  {
     path: "/user-profile",
     title: "Inventory",
     icon: "fas fa-boxes text-danger",
     class: "",
     param: {},
     children: [],
   }, */
  {
    path: ["/travel", "new"],
    title: "Travel & Claim",
    icon: "fas fa-bus text-yellow",
    class: "",
    param: {},
    children: [
      {
        path: ["/travel", "new"],
        title: "Travel Form",
        icon: "fas fa-bus text-yellow",
        class: "",
        param: {},
        children: []
      },
      {
        path: ["/claim-list", "Open"],
        title: "Claim",
        icon: "fas fa-bus text-yellow",
        class: "",
        param: {},
        children: []
      },
    ]
  },
  // {
  //   path: ["/transfer","new"],
  //   title: "Transfer",
  //   icon: "fas fa-exchange-alt text-pink",
  //   class: "",
  //   param: {},
  //   children: [],
  // },
  {
    path: ["/appraisal-list", "Open"],
    title: "Appraisal",
    icon: "fas fa-money-check-alt text-info",
    class: "",
    param: {},
    children: [],
  },
  {
    path: ["/recruitment"],
    title: "Recruitment",
    icon: "fas fa-user-check text-primary",
    class: "",
    param: {},
    children: [],
  },
  {
    path: ["/resignation", "new"],
    title: "Resignation",
    icon: "fas fa-times text-danger",
    class: "",
    param: {},
    children: [
      {
        path: ["/resignation", "new"],
        title: "Resignation Request",
        icon: "fas fa-times text-danger",
        class: "",
        param: {},
        children: [],
      },
      {
        path: ["/clearance-list", "Pending Approval"],
        title: "Clearance",
        icon: "fas fa-check text-info",
        class: "",
        param: {},
        children: [],
      },
      {
        path: ["/exit-interview-list", "Released", false],
        title: "Exit Interview",
        icon: "fas fa-sign-out-alt text-pink",
        class: "",
        param: {},
        children: [],
      }
    ]
  },

  {
    path: ["/inventory-list", "Open"],
    title: "Inventory/FA",
    icon: "fas fa-boxes text-primary",
    class: "",
    param: {},
    children: [],
  },
  {
    path: ["/reports"],
    title: "Reports",
    icon: "fas fa-file-alt text-success",
    class: "",
    param: {},
    children: [],
  },
  {
    path: ["/training-list","open"],
    title: "Training",
    icon: "fas fa-newspaper text-yellow",
    class: "",
    param: {},
    children: [],
  },
  {
    path: ["/onboarding-list","pending"],
    title: "Onboarding",
    icon: "fas fa-sign-out-alt text-pink text-primary",
    class: "",
    param: {},
    children: [],
  }
  /*
  {
    path: "/user-profile",
    title: "Appraisal",
    icon: "fas fa-level-up-alt text-warning",
    class: "",
    param: {},
    children: [],
  },
  {
    path: "/user-profile",
    title: "Resignation",
    icon: "fas fa-sign-out-alt text-danger",
    class: "",
    param: {},
    children: [],
  },
  {
    path: "/user-profile",
    title: "Documents",
    icon: "fas fa-paperclip text-info",
    class: "",
    param: {},
    children: [],
  },
  {
    path: "/user-profile",
    title: "Reports",
    icon: "fas fa-newspaper text-yellow",
    class: "",
    param: {},
    children: [],
  }, */
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isHidden = true;
  public isCollapsed: boolean[] = [];
  baseUrl: string;

  constructor(
    private router: Router,
    @Inject("BASE_URL") _baseUrl: string,
    private toastrService: ToastrService,
    private http: HttpClient,
    private dataService: DataService,
    private authService: AuthService

  ) {
    this.baseUrl = _baseUrl;
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isHidden = true;
    });
  }
  logout() {
    localStorage.removeItem("currentUser");
    this.router.navigateByUrl("/login");
  }
  ping() {
    this.dataService.ping().subscribe(
      (result) => {
        this.toastrService.success(
          "Connection established successfully",
          "Success"
        );
      },
      (error) => {
        this.toastrService.error(error.error.error.message);
      }
    );
  }

  approvalCount: number = 0;
  getApprovalCount() {

    let reqBody = {
      "functionType": "ApprovalList",
      "empid": this.authService.CurrentUserValue.username
    };
    this.dataService.commonPost(`PortalFunction`, reqBody)
      .subscribe(
        (result) => {
          console.log(result, "Appraisal Count")
          var tempApprovalCount =
            result['approvalPendingLeave'] +
            result['approvalPendingSalAdvance'] +
            result['approvalPendingTransfer'] +
            result['approvalPendingTravel'] +
            result['approvalPendingTravelSettlement'] +
            result['approvalPendingUpdateAtten'] +
            result['approvalPendingresignation'] +
            result['approvalPendingSubstleave'] +
            result['approvalPendingAppraisal'] +
            result['approvalPendingClearance'] +
            result['approvalPendingOnboarding']


          this.approvalCount = tempApprovalCount;
        },
        (error) => {
          /*  this.toastrService.error(error.error.error.message); */
        }
      );
  }
}
