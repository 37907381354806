export class Employee {
  Age: string;
  Bank_Account_No: string;
  Bank_Name: string;
  Birth_Date: string;
  Blood_Group: string;
  Branch: string;
  BusinessUnit: string;
  CIT_No: string;
  CitizenShip_Number: string;
  Company_E_Mail: string;
  Contact_No_1: string;
  Contact_No_2: string;
  CorrespondanceAddress: string;
  Date_of_Birth_Actual: string;
  Date_of_Birth_B_S: string;
  Department: string;
  Designation: string;
  E_Mail: string;
  Employee_Type: string;
  Full_Name: string;
  Gender: string;
  Job_Title: string;
  Join_Date: string;
  Machine_Employee_ID: number;
  Marital_Status: string;
  Mobile_Phone_No: string;
  Name_1: string;
  Name_2: string;
  No: string;
  PAN_No: string;
  Supervisor1_Contact: string;
  Supervisor2_Contact: string;
  Supervisor1_Email: string;
  Supervisor2_Email: string;
  Service_Period: string;
  PermanentAddress: string;
  Phone_No: string;
  Probation_End_Date: string;
  Relation_1: string;
  Relation_2: string;
  SSF_No: string;
  Supervisor_1_Name: string;
  Supervisor_2_Name: string;
  Supervisor_1_Code: string;
  Supervisor_2_Code: string;
  Allow_Paternity_Leave: boolean;
  Allow_Mourning_Leave: boolean;
  Allow_Maternity_Leave: boolean;
  Allow_Unpaid_Leave: boolean;
  Allow_Marrige_Leave: boolean;
}
