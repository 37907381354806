import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "models/user";
import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { map } from 'rxjs/operators';
import IdleTimer from "../layouts/admin-layout/idel-timer.js"

@Injectable({
  providedIn: "root",
})
export class AuthService {
  timer = IdleTimer;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  empId: string;
  username: string;
  public baseUrl: string;
  public http: HttpClient;
  public user: User;

  constructor(
    _http: HttpClient,
    @Inject("BASE_URL") _baseUrl: string,
    private router: Router
  ) {
    this.baseUrl = _baseUrl;
    this.http = _http;
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  imgSrc: BehaviorSubject<string>;

  public get CurrentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setUser(res) {
    localStorage.setItem("currentUser", JSON.stringify(res));
    window.sessionStorage.setItem('currentUser', JSON.stringify(res));
    this.currentUserSubject.next(res);
  }
  setProfilePic(pUrl) {
    this.imgSrc.next(pUrl);
  }
  login(credentials) {
    return this.http.post<any>('/api/authentication', credentials)
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }
  logout() {
    window.sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.stopRefreshTokenTimer();
   

    if (window.innerWidth <= 567) {
      localStorage.removeItem("currentUser");
    } else {
      localStorage.clear();
    }
    // this.timer.cleanUp();
  }
  refreshToken() {
    return this.http.post<any>('/api/Authentication/refresh-token', this.CurrentUserValue)
      .pipe(map((user) => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  // helper methods
  private refreshTokenTimeout;

  startRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(this.CurrentUserValue.expiresAt);
    const timeout = expires.getTime() - Date.now() - (120 * 1000);

    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }


  sessionExpire() {
    if (localStorage.getItem("currentUser") == null) {
      this.router.navigateByUrl("login");
    }
  }
}
