import { Component, OnInit, ElementRef, Inject } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { AuthService } from "src/app/authservice/auth.service";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { DataService } from "src/app/sharedservice/data.service";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  baseUrl: string;
  imgUrl: Observable<string>;
  imageSrc: any;
  base64Url: string;
  functionType: string;
  newNotification: boolean = false;
  newNoticeCount = 0;
  currentUser = this.authService.CurrentUserValue;
  approvalCount: number = 0;
  conpanyDetails = {};
  constructor(
    location: Location,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject("BASE_URL") _baseUrl: string,
    private toastrService: ToastrService,
    private dataService: DataService
  ) {
    this.location = location;
    this.baseUrl = _baseUrl;
  }
  employeeName: string = this.authService.CurrentUserValue.empName;
  firstName: string = this.employeeName.slice(
    0,
    this.employeeName.indexOf(" ")
  );

  ngOnInit() {
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    this.getNotices();
    this.getBase64Image();
    this.getApprovalCount();
    this.getCompanyDetails();
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      if (titlee.indexOf("?") > 0) {
        titlee = titlee.slice(1, titlee.indexOf("?"));
      } else {
        titlee = titlee.slice(1);
      }
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Batas";
  }
  getCompanyDetails() {
    this.http.get<any>(`api/Authentication/GetConfig`).subscribe(res => {
      this.conpanyDetails = res
      console.log(res)
    })
  }

  logout() {
    this.authService.logout();
    if (this.authService.CurrentUserValue == null) {
      this.router.navigateByUrl("login");
    } else {
      this.toastrService.warning("Please try again!")
    }
  }
  ping() {
    this.dataService.ping().subscribe(
      (result) => {
        this.toastrService.success(
          "Connection established successfully",
          "Success"
        );
      },
      (error) => {
        this.toastrService.error(error.error.error.message);
      }
    );
  }
  getBase64Image() {
    this.functionType = "getPicture";

    let reqBody = {
      "functionType": "getPicture"
    };
    this.dataService.commonPost(`PortalFunction`, reqBody)
      .subscribe(
        (result) => {
          this.base64Url = result.empPicture;
          if (this.base64Url === "No Image") {
            this.imageSrc = "assets/img/avatar.png";
            return;
          }
          this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
            `data:image/png;base64, ${this.base64Url}`
          );
        },
        (error) => {
          /*  this.toastrService.error(error.error.error.message); */
        }
      );
  }
  getNotices() {
    let path = `NoticeBoard`;
    this.dataService.commonGetOptimized(path).subscribe(
      (result) => {
        var newNotice = result.value.filter((x) =>
          this.calculateDays(x.Start_Date)
        );
        this.newNoticeCount = newNotice.length;
        if (this.newNoticeCount > 0) {
          this.newNotification = true;
        }
      },
      (error) => {
      }
    );
  }
  calculateDays(startDate: any) {
    if (startDate != undefined) {
      var sDay = new Date(startDate).getTime();
      var eDay = new Date().getTime();
      var days = (eDay - sDay) / (1000 * 3600 * 24);
      if (days <= this.currentUser.noticeNotificationDays) {
        return true;
      }
    }
    return false;
  }
  // getApprovalCount() {
  //   let path = `ApprovalAPI`;
  //   this.dataService.commonGetOptimized(path).subscribe((result) => {
  //     debugger;
  //     var tempArr = result.value.filter(
  //       (x) => x.Status == "Open" && x.Approver_ID == this.currentUser.username.toUpperCase()
  //     );

  //     this.approvalCount = tempArr.length;

  //   });
  // }
  getApprovalCount() {

    let reqBody = {
      "functionType": "ApprovalList",
      "empid": this.authService.CurrentUserValue.username
    };
    this.dataService.commonPost(`PortalFunction`, reqBody)
      .subscribe(
        (result) => {
          console.log(result, "Appraisal Count")
          var tempApprovalCount =
            result['approvalPendingLeave'] +
            result['approvalPendingSalAdvance'] +
            result['approvalPendingTransfer'] +
            result['approvalPendingTravel'] +
            result['approvalPendingTravelSettlement'] +
            result['approvalPendingUpdateAtten'] +
            result['approvalPendingresignation'] +
            result['approvalPendingSubstleave'] +
            result['approvalPendingAppraisal'] +
            result['approvalPendingClearance'] +
            result['approvalPendingOnboarding']


          this.approvalCount = tempApprovalCount;
        },
        (error) => {
          /*  this.toastrService.error(error.error.error.message); */
        }
      );
  }
}
