import { HttpClient } from "@angular/common/http";
import { Location } from '@angular/common';

import { Inject, Injectable } from "@angular/core";
import { Employee } from "models/employee";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../authservice/auth.service";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class DataService {
  path: string;
  employeeID: string = this.authService.CurrentUserValue.empId;
  private baseUrl: string;

  employeeDetail: Employee = new Employee();
  config = {
    firstDayOfWeek: "su",
    monthFormat: "MMM, YYYY",
    disableKeypress: false,
    allowMultiSelect: false,
    closeOnSelect: undefined,
    closeOnSelectDelay: 100,
    onOpenDelay: 0,
    weekDayFormat: "ddd",
    appendTo: document.body,
    drops: "down",
    opens: "right",
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    format: "YYYY-MM-DD",
    yearFormat: "YYYY",
    showGoToCurrent: true,
    dayBtnFormat: "DD",
    monthBtnFormat: "MMM",
    hours12Format: "hh",
    hours24Format: "HH",
    meridiemFormat: "A",
    minutesFormat: "mm",
    minutesInterval: 1,
    secondsFormat: "ss",
    secondsInterval: 1,
    showSeconds: false,
    showTwentyFourHours: true,
    timeSeparator: ":",
    multipleYearsNavigateBy: 10,
    showMultipleYearsNavigation: false,
    locale: "en",
  };
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private location: Location,
    private toastrService: ToastrService,
    @Inject("BASE_URL") _baseUrl: string
  ) {
    this.baseUrl = _baseUrl;
  }

  getEmpDetail() {
    this.path = `EmployeeAPI('${this.employeeID}')`;
    return this.http.get<any>(
      `${this.baseUrl}api/Common/CommonGet?&path=${this.path}`
    );
  }

  getPortalFunctionData(funBody: any) {
    this.path = `PortalFunction`;
    return this.http.post<any>(
      `${this.baseUrl}api/Common/CommonPost?&path=${this.path}`,
      funBody
    );
  }

  commonGet(path: any) {
    debugger;
    return this.http.get<any>(
      `${this.baseUrl}api/Common/CommonGet?&path=${path}`
    );
  }
  commonGetOptimized(path: any) {
    return this.http.get<any>(
      `${this.baseUrl}api/Common/CommonEmpActivityGet?&path=${path}`
    );
  }

  commonPost(path: any, funBody: any) {
    return this.http.post<any>(
      `${this.baseUrl}api/Common/CommonPost?&path=${path}`,
      funBody
    );
  }
  commonPatch(path: any, funbody: any) {
    return this.http.patch<any>(
      `${this.baseUrl}api/Common/UpdateEmployee?&path=${path}&odata_etag=*`,
      funbody
    );
  }

  getCurrentApprover(docno: any) {
    let requestBody = {
      "functionType": "Current Approver",
      "docNo": docno
    };
    let path = `PortalFunction`;
    return this.http.post<any>(
      `${this.baseUrl}api/Common/CommonPost?&path=${path}`,
      requestBody
    );
  }

  onCalcelEmployeActivity(docno: any) {
    let requestBody = {
      "functionType": "Calcel Approval",
      "docNo": docno,
      "empid": this.employeeID
    };
    let path = `PortalFunction`;
    return this.http.post<any>(
      `${this.baseUrl}api/Common/CommonPost?&path=${path}`,
      requestBody
    ).subscribe((result) => {
      this.location.back();
    })
  }

  ping() {
    return this.http.get<any>(`${this.baseUrl}api/Common/CommonGet`);
  }

  get BaseUrl() {
    return this.baseUrl;
  }
  downloadAttachmentInitiate(fname: any, reportType: any) {
    if (reportType == "Notice Board") {
      this.path = `AttachmentAPI?$filter=Attachment_Type eq '${reportType}' and Document_No eq '${fname}'`;
      this.http
        .get<any>(`${this.baseUrl}api/Common/CommonGet?&path=${this.path}`)
        .subscribe(
          (result) => {
            if (result.value.length == 0) {
              this.toastrService.warning(
                "No document was attached",
                "Could not complete action"
              );
            } else {

              let attJson = {
                functionType: "downloadAttachment",
                UserFilename: result.value[0].File_Name,
              };
              this.path = "PortalFunction";
              this.http
                .post<any>(
                  `${this.baseUrl}api/Common/CommonPost?&path=${this.path}`,
                  attJson
                )
                .subscribe(
                  (result) => {

                    let b64Str = result.filename.slice(
                      result.filename.lastIndexOf(" : ") + 4,
                      result.filename.length - 2
                    );
                    let ext = result.filename.slice(
                      result.filename.indexOf(".") + 1,
                      result.filename.indexOf(",") - 1
                    );

                    this.downloadFile(b64Str, reportType, ext);
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            }
          },
          (error) => {
            this.toastrService.error(error.error.error.message);
          }
        );
    }
    let b64Str = fname.slice(fname.lastIndexOf(" : ") + 4, fname.length - 2);
    let ext = fname.slice(
      fname.indexOf(":") + 3,
      fname.indexOf(",") - 1
    );

    this.downloadFile(b64Str, reportType, ext);
  }
  downloadFile(b64encodedString: string, rType: string, ext: string) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, "text/plain");
      saveAs(blob, `${rType}.${ext}`);
    }
  }
  public base64ToBlob(b64Data, contentType = "", sliceSize = 512) {

    b64Data = b64Data.replace(/\s/g, ""); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

}
