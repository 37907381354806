import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/authservice/auth.service';
import IdleTimer from "../admin-layout/idel-timer.js"

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  timer = IdleTimer;
  constructor(private authService: AuthService, private router: Router, private activatedRoutes: ActivatedRoute) { }

  ngOnInit() {
    if (localStorage.getItem("currentUser")) {
      this.idelTime();
      this.refreshTokenTimer();
    }
    // this.activatedRoutes.params.subscribe(res => {
    //   console.log(res,"RESSSSSS")
    // })

  }

  refreshTokenTimer() {
    this.authService.startRefreshTokenTimer()
  }
  idelTime() {
    this.timer = new IdleTimer({
      timeout: 300, //expired after 20 min
      onTimeout: () => {
        this.authService.logout()
        this.timer.cleanUp();
        alert("You have been logged out for being inactive")
        const returnModule = location.hash.substring(2);
        this.router.navigate(['/login'], { queryParams: { returnUrl: returnModule } });
      }
    });
  }

  ngOnDestroy() {
    this.timer.cleanUp();
  }

}
